import { Box, Text, Image, Input, VStack, HStack, Button, Divider, Flex } from '@chakra-ui/react'
import Card from 'components/UIComponents/Card'
import { US } from 'country-flag-icons/react/3x2'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

const WalmartSellerConnect = () => {
	const [hovered, setHovered] = useState(false)

	const history = useHistory()

	const handlePolicy = () => {
		history.push('/privacy_policy')
	}

	const handleTerms = () => {
		history.push('/terms_and_conditions')
	}

	function onAuthClick() {
		window.open(import.meta.env.VITE_WALMART_AUTH_URL, '_blank')
	}

	return (
		<Card margin={10}>
			<Flex
				w='100%'
				align='center'
				justify='center'
				flexDirection={{ base: 'column', md: 'row' }}
				bg='white'
				p={{ base: 4, md: 8 }}
			>
				<VStack
					align='flex-start'
					spacing={4}
					w={{ base: '100%', md: '50%' }}
					maxW={{ base: '90%', md: '500px' }}
					h='auto'
					justify='space-between'
				>
					<Box w='100%'>
						<Text fontSize={{ base: '2xl', md: '3xl' }}>
							Add a{' '}
							<Text as='span' color='orange.400' fontWeight='bold'>
								Walmart{' '}
							</Text>
							Seller Account
						</Text>
						<Divider orientation='horizontal' borderColor='gray.900' mt={4} />
					</Box>

					<VStack align='flex-start' gap={0} spacing={0}>
						<Box as='span' fontWeight='bold' color='orange.400' fontSize={{ base: '2xl', md: '3xl' }}>
							Great,
						</Box>
						<Text fontSize={{ base: 'xl', md: '3xl' }} fontWeight='bold'>
							let’s connect your existing Walmart account!
						</Text>

						<Text fontSize={{ base: 'lg', md: 'xl' }} color='gray.600' mt={10}>
							Connecting your Walmart account to Valence Intelligence will unlock powerful tools and insights for your
							business.
						</Text>
					</VStack>
				</VStack>

				<VStack w={{ base: '100%', md: '50%' }} maxW={{ base: '90%', md: '450px' }} h={{ base: 'auto', md: 'auto' }}>
					<Image
						maxW={{ base: '60%', md: '100%' }}
						src='https://mihostedimages.s3.amazonaws.com/Marketplace.svg'
						alt='Marketplace Illustration'
					/>
				</VStack>
			</Flex>

			<Flex
				w='100%'
				align='center'
				justify='center'
				flexDirection={{ base: 'column', md: 'row' }}
				bg='white'
				p={{ base: 4, md: 8 }}
				gap={4}
			>
				<VStack align='flex-start' spacing={4} w={{ base: '90%', md: '950px' }} justify='space-between'>
					<Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight='bold'>
						Input a display name for your Walmart Seller Account
					</Text>
				</VStack>
			</Flex>

			<Flex
				w='100%'
				align='center'
				justify='center'
				flexDirection={{ base: 'column', md: 'row' }}
				bg='white'
				p={{ base: 4, md: 8 }}
				gap={4}
			>
				<VStack align='flex-start' spacing={4} w={{ base: '90%', md: '950px' }} justify='space-between'>
					<Text fontSize={{ base: 'lg', md: 'xl' }} color='gray.600' mt={3}>
						Walmart Seller Alias
					</Text>

					<Input
						placeholder='My Walmart Store'
						size='lg'
						focusBorderColor='blue.500'
						borderColor='gray.400'
						w={{ base: '90%', md: '32%' }}
					/>
					<Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight='bold'>
						Marketplace Region
					</Text>

					<VStack
						h={{ base: '250px', md: '330px' }}
						align='center'
						spacing={0}
						justify='center'
						border='1px'
						borderColor='gray.300'
						w={{ base: '90%', md: '32%' }}
						mb={{ base: 4, md: 0 }}
						onMouseEnter={() => setHovered(true)}
						onMouseLeave={() => setHovered(false)}
						_hover={{ borderColor: 'vBlue.500' }}
					>
						<Flex
							justify='center'
							align='center'
							flexWrap='wrap'
							boxSize={hovered ? { base: '100px', md: '200px' } : { base: '200px', md: '250px' }}
						>
							<Box m='10px'>
								<US title='United States' />
								<span>US</span>
							</Box>
						</Flex>
						<Button
							m={2}
							h={hovered ? '150px' : { base: '100px', md: '200px' }}
							borderColor='gray.200'
							variant='outline'
							bg={hovered ? 'vBlue.500' : 'white'}
							color={hovered ? 'white' : 'gray.800'}
							_hover={{ bg: 'vBlue.500', color: 'white', borderColor: 'vBlue.500' }}
							w='95%'
							onClick={onAuthClick}
						>
							<VStack spacing={2}>
								<Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight='bold' color={hovered ? 'white' : 'gray.800'}>
									North America
								</Text>
								<Box w='100%' padding='0 10px' alignSelf='flex-start'>
									<Text fontSize={{ base: 'xs', md: 'sm' }} color={hovered ? 'white' : 'gray.500'} whiteSpace='normal'>
										US
									</Text>
								</Box>
							</VStack>
						</Button>
					</VStack>
				</VStack>
			</Flex>

			<Box w='100%' bg='white' p={{ base: 3, md: 5 }} textAlign='center' position='relative' bottom='0'>
				<HStack spacing={{ base: 2, md: 4 }} justify='center' wrap='wrap'>
					<Button onClick={handlePolicy} bg='white' variant='link'>
						<Text fontSize={{ base: 'xs', md: 'sm' }} color='gray.600'>
							Privacy Policy
						</Text>
					</Button>
					<Button onClick={handleTerms} bg='white' variant='link'>
						<Text fontSize={{ base: 'xs', md: 'sm' }} color='gray.600'>
							Terms of Use
						</Text>
					</Button>
					<Text fontSize={{ base: 'xs', md: 'sm' }} color='gray.600'>
						© 2024, Valence Intelligence
					</Text>
				</HStack>
			</Box>
		</Card>
	)
}

export default WalmartSellerConnect

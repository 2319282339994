import { Box, Text, Image, HStack, Button, Flex, VStack, Divider } from '@chakra-ui/react'
import { FR, NL, PL, DE, ES, SE, TR, BE, CA, MX, US, BR } from 'country-flag-icons/react/3x2'

import Card from 'components/UIComponents/Card'
import { useUserData } from 'hooks/user'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { openSCLinkAccount } from 'utils'
import { useBreakpointValue } from '@chakra-ui/react'

const AmazonSellerConnect = () => {
	const history = useHistory()
	const [hoveredNa, setHoveredNA] = useState(false)
	const [hoveredEu, setHoveredEu] = useState(false)
	const { data: currentUser } = useUserData()

	const handlePolicy = () => {
		history.push('/privacy_policy')
	}

	const handleTerms = () => {
		history.push('/terms_and_conditions')
	}

	const handleClick = useCallback(() => {
		openSCLinkAccount(currentUser.CognitoSub)
	}, [currentUser])

	const flagStyles = {
		margin: '15px',
	}
	const visibleFlags =
		useBreakpointValue({
			base: 4,
			md: 10,
		}) || 10

	return (
		<Card margin={10}>
			<Flex
				w='100%'
				align='center'
				justify='center'
				flexDirection={{ base: 'column', md: 'row' }}
				bg='white'
				p={{ base: 4, md: 8 }}
				wrap='wrap'
			>
				<VStack align='flex-start' spacing={4} w={{ base: '90%', md: '500px' }} h='auto' justify='space-between'>
					<Box w='100%'>
						<Text fontSize={{ base: 'xl', md: '2xl' }}>
							Add a{' '}
							<Text as='span' color='orange.400' fontWeight='bold'>
								Amazon{' '}
							</Text>
							Seller Account
						</Text>
						<Divider orientation='horizontal' borderColor='gray.900' mt={4} />
					</Box>

					<VStack align='flex-start' spacing={4} w={{ base: '90%', md: '500px' }} justify='space-between'>
						<Box w='100%'>
							<Text as='span' fontWeight='bold' color='orange.400' fontSize={{ base: '2xl', md: '3xl' }}>
								Great,
							</Text>
							<Text fontSize={{ base: '2xl', md: '3xl' }} fontWeight='bold' color='black'>
								let's connect your existing account!
							</Text>
						</Box>

						<Text fontSize={{ base: 'lg', md: 'xl' }} color='gray.600' mt={10}>
							Connecting your Amazon account to Valence Intelligence will unlock powerful tools and insights for your
							business.
						</Text>
					</VStack>
				</VStack>

				<VStack align='flex-end' spacing={4} justify='center'>
					<Image
						maxW={{ base: '100%', md: '400px' }}
						h={{ base: '200px', md: '400px' }}
						src='https://mihostedimages.s3.amazonaws.com/Account_Login.svg'
						alt='Marketplace Illustration'
					/>
				</VStack>
			</Flex>

			<Flex w='100%' align='center' justify='center' flexDirection='column' bg='white'>
				<VStack
					align='flex-start'
					w={{ base: '100%', sm: '90%', md: '80%', lg: '70%', xl: '60%' }}
					p={{ base: 2, sm: 4, md: 6, lg: 8, xl: 10 }}
					justify='space-between'
				>
					<Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight='bold'>
						Choose the Amazon region in which you are selling
					</Text>
				</VStack>
			</Flex>
			<HStack
				align='center'
				justify='center'
				flexDirection={{ base: 'column', lg: 'row' }}
				bg='white'
				gap={{ base: '20px', md: '150px' }}
			>
				<VStack
					h={{ base: '350px', md: '330px' }}
					spacing={0}
					border='1px'
					borderColor='gray.300'
					w={{ base: '90%', lg: '22%' }}
					mb={{ base: 4, md: 0 }}
					onMouseEnter={() => setHoveredNA(true)}
					onMouseLeave={() => setHoveredNA(false)}
					_hover={{ borderColor: 'vBlue.500' }}
				>
					<Flex
						flexDirection={{ base: 'column', md: 'row' }}
						gap='15px'
						justify='center'
						align='center'
						flexWrap='wrap'
						boxSize={hoveredNa ? { base: '200px', md: '250px' } : { base: '150px', md: '250px' }}
					>
						<Box m='10px'>
							<US title='United States' />
							<span>US</span>
						</Box>
						<Box {...flagStyles}>
							<CA title='Canada' />
							<span>CA</span>
						</Box>
						<Box {...flagStyles}>
							<MX title='Mexico' />
							<span>MX</span>
						</Box>
						<Box {...flagStyles}>
							<BR title='Brazil' />
							<span>BR</span>
						</Box>
					</Flex>
					<Button
						m={2}
						h={hoveredNa ? '150px' : { base: '100px', md: '200px' }}
						borderColor='gray.200'
						variant='outline'
						bg={hoveredNa ? 'vBlue.500' : 'white'}
						color={hoveredNa ? 'white' : 'gray.800'}
						_hover={{ bg: 'vBlue.500', color: 'white', borderColor: 'vBlue.500' }}
						w='95%'
						onClick={handleClick}
					>
						<VStack spacing={2}>
							<Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight='bold' color={hoveredNa ? 'white' : 'gray.800'}>
								North America
							</Text>
							<Box w='100%' padding='0 10px' alignSelf='flex-start'>
								<Text fontSize={{ base: 'xs', md: 'sm' }} color={hoveredNa ? 'white' : 'gray.500'} whiteSpace='normal'>
									US, CA, MX, BR
								</Text>
							</Box>
						</VStack>
					</Button>
				</VStack>

				<VStack
					h={{ base: '250px', md: '330px' }}
					spacing={0}
					border='1px'
					borderColor='gray.300'
					w={{ base: '90%', lg: '22%' }}
					mb={{ base: 4, md: 0 }}
					onMouseEnter={() => setHoveredEu(true)}
					onMouseLeave={() => setHoveredEu(false)}
					_hover={{ borderColor: 'vBlue.500' }}
				>
					<Flex
						gap='15px'
						flexWrap='wrap'
						justify='center'
						align='center'
						boxSize={hoveredEu ? { base: '100px', md: '200px' } : { base: '150px', md: '250px' }}
					>
						{visibleFlags >= 1 && (
							<Box m='10px'>
								<FR title='FR' />
								<span>FR</span>
							</Box>
						)}
						{visibleFlags >= 2 && (
							<Box {...flagStyles}>
								<NL title='NL' />
								<span>NL</span>
							</Box>
						)}
						{visibleFlags >= 3 && (
							<Box {...flagStyles}>
								<PL title='PL' />
								<span>PL</span>
							</Box>
						)}
						{visibleFlags >= 4 && (
							<Box {...flagStyles}>
								<DE title='DE' />
								<span>DE</span>
							</Box>
						)}
						{visibleFlags >= 5 && (
							<Box {...flagStyles}>
								<ES title='ES' />
								<span>ES</span>
							</Box>
						)}
						{visibleFlags >= 6 && (
							<Box {...flagStyles}>
								<SE title='SE' />
								<span>SE</span>
							</Box>
						)}
						{visibleFlags >= 7 && (
							<Box {...flagStyles}>
								<TR title='TR' />
								<span>TR</span>
							</Box>
						)}
						{visibleFlags >= 8 && (
							<Box {...flagStyles}>
								<BE title='BE' />
								<span>BE</span>
							</Box>
						)}
					</Flex>
					<Button
						m={2}
						h={hoveredEu ? '150px' : { base: '100px', md: '200px' }}
						borderColor='gray.200'
						variant='outline'
						bg={hoveredEu ? 'vBlue.500' : 'white'}
						color={hoveredEu ? 'white' : 'gray.800'}
						_hover={{ bg: 'vBlue.500', color: 'white', borderColor: 'vBlue.500' }}
						w='95%'
						onClick={handleClick}
					>
						<VStack spacing={2}>
							<Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight='bold' color={hoveredEu ? 'white' : 'gray.800'}>
								Europe
							</Text>
							<Box w='100%' padding='0 10px' alignSelf='flex-start'>
								<Text fontSize={{ base: 'xs', md: 'sm' }} color={hoveredEu ? 'white' : 'gray.500'} whiteSpace='normal'>
									FR, NL, PL, GB, DE, ES, SE, TR, BE, IT
								</Text>
							</Box>
						</VStack>
					</Button>
				</VStack>
			</HStack>
			<Box w='100%' bg='white' p={{ base: 3, md: 5 }} textAlign='center' position='relative' bottom='0'>
				<HStack spacing={{ base: 2, md: 4 }} justify='center' wrap='wrap'>
					<Button onClick={handlePolicy} bg='white' variant='link'>
						<Text fontSize={{ base: 'xs', md: 'sm' }} color='gray.600'>
							Privacy Policy
						</Text>
					</Button>
					<Button onClick={handleTerms} bg='white' variant='link'>
						<Text fontSize={{ base: 'xs', md: 'sm' }} color='gray.600'>
							Terms of Use
						</Text>
					</Button>
					<Text fontSize={{ base: 'xs', md: 'sm' }} color='gray.600'>
						© 2024, Valence Intelligence
					</Text>
				</HStack>
			</Box>
		</Card>
	)
}

export default AmazonSellerConnect

import { Box, VStack, HStack, Text, Image, Button, Divider, Flex } from '@chakra-ui/react'
import Card from 'components/UIComponents/Card'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

const MarketplaceSelection = () => {
	const history = useHistory()
	const [hoveredAmazon, setHoveredAmazon] = useState(false)
	const [hoveredWalmart, setHoveredWalmart] = useState(false)

	const handlePolicy = () => {
		history.push('/privacy_policy')
	}
	const handleTerms = () => {
		history.push('/terms_and_conditions')
	}

	const handleAmazonSellerConnect = () => {
		history.push('/marketplace_selection/amazon_seller_connect')
	}

	const handleWalmartSellerConnect = () => {
		history.push('/marketplace_selection/walmart_seller_connect')
	}

	return (
		<Card margin={10}>
			<Flex
				w='100%'
				align='center'
				justify='center'
				flexDirection={{ base: 'column', lg: 'row' }}
				p={{ base: 4, md: 6 }}
			>
				<VStack align='flex-start' spacing={4} w={{ base: '90%', md: '500px' }} h='300px' justify='space-between'>
					<Box w='100%'>
						<Text fontSize={{ base: '2xl', md: '4xl' }} fontWeight='bold' color='black'>
							Choose your{' '}
							<Text as='span' color='orange.400'>
								Platform
							</Text>
						</Text>
						<Divider orientation='horizontal' borderColor='gray.900' mt={4} />
					</Box>

					<VStack
						align='flex-start'
						gap={0}
						spacing={0}
						w={{ base: '100%', sm: '90%', md: '80%', lg: '70%', xl: '60%' }}
						p={{ base: 2, sm: 4, md: 6, lg: 8, xl: 10 }}
					>
						<Text fontSize={{ base: '2xl', md: '3xl' }} marginBottom={-5}>
							Select the
						</Text>
						<Box as='span' fontWeight='bold' color='orange.400' fontSize={{ base: '4xl', md: '6xl' }} pt={0}>
							MARKETPLACE
						</Box>
						<Text fontSize={{ base: '2xl', md: '3xl' }}>you would like to connect.</Text>
					</VStack>
				</VStack>

				<VStack align='flex-end' spacing={4}>
					<Image
						maxW={{ base: '200px', md: '450px' }}
						h={{ base: '300px', md: '650px' }}
						src='https://mihostedimages.s3.amazonaws.com/Marketplace.svg'
						alt='Marketplace Illustration'
					/>
				</VStack>
			</Flex>

			<Flex
				w='100%'
				align='center'
				justify='center'
				flexDirection={{ base: 'column', md: 'row' }}
				bg='white'
				p={{ base: 4, md: 8 }}
				mt={{ base: -5, md: -100 }}
			>
				<VStack align='flex-start' spacing={4} w={{ base: '90%', md: '950px' }} justify='space-between'>
					<Box>
						<Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight='bold' color='gray.800'>
							What account would you like to{' '}
							<Text as='span' color='orange.400'>
								connect
							</Text>
							?
						</Text>
					</Box>
				</VStack>
			</Flex>

			<HStack
				w='100%'
				align='center'
				justify='center'
				flexDirection={{ base: 'column', lg: 'row' }}
				bg='white'
				p={{ base: 4, md: 8 }}
				gap={{ base: '50px', md: '100px' }}
				h={{ base: 'auto', md: 'auto' }}
			>
				<VStack
					h={{ base: '250px', md: '350px' }}
					align='center'
					spacing={0}
					justify='center'
					border='1px'
					borderColor='gray.300'
					w={{ base: '90%', lg: '22%' }}
					mb={{ base: 4, md: 0 }}
					onMouseEnter={() => setHoveredAmazon(true)}
					onMouseLeave={() => setHoveredAmazon(false)}
					_hover={{ borderColor: 'vBlue.500' }}
				>
					<Image
						src='https://mihostedimages.s3.amazonaws.com/amazon-2-logo-svgrepo-com.svg'
						alt='Amazon Logo'
						mb={2}
						boxSize={hoveredAmazon ? { base: '200px', md: '250px' } : { base: '150px', md: '200px' }}
					/>
					<Button
						m={2}
						h={hoveredAmazon ? '250px' : { base: '100px', md: '400px' }}
						borderColor='gray.200'
						variant='outline'
						bg={hoveredAmazon ? 'vBlue.500' : 'white'}
						color={hoveredAmazon ? 'white' : 'gray.800'}
						_hover={{ bg: 'vBlue.500', color: 'white', borderColor: 'vBlue.500' }}
						mt={-12}
						w='95%'
						onClick={handleAmazonSellerConnect}
					>
						<VStack spacing={2}>
							<Text fontSize={{ base: 'lg', md: '2xl' }} fontWeight='bold' color={hoveredAmazon ? 'white' : 'gray.800'}>
								Connect to Amazon
							</Text>
							<Box w='100%' padding='0 10px' alignSelf='flex-start'>
								<Text
									fontSize={{ base: 'xs', md: 'sm' }}
									color={hoveredAmazon ? 'white' : 'gray.500'}
									whiteSpace='normal'
								>
									Connect your Amazon Seller Central account to Valence Intelligence
								</Text>
							</Box>
						</VStack>
					</Button>
				</VStack>

				<VStack
					h={{ base: '250px', md: '350px' }}
					align='center'
					spacing={0}
					justify='center'
					border='1px'
					borderColor='gray.300'
					w={{ base: '90%', lg: '22%' }}
					mb={{ base: 4, md: 0 }}
					onMouseEnter={() => setHoveredWalmart(true)}
					onMouseLeave={() => setHoveredWalmart(false)}
					_hover={{ borderColor: 'vBlue.500' }}
				>
					<Image
						src='https://mihostedimages.s3.amazonaws.com/walmart-logo-svgrepo-com.svg'
						alt='Walmart Logo'
						mb={2}
						boxSize={hoveredWalmart ? { base: '200px', md: '250px' } : { base: '150px', md: '200px' }}
					/>
					<Button
						m={2}
						h={hoveredWalmart ? '250px' : { base: '100px', md: '400px' }}
						borderColor='gray.200'
						variant='outline'
						bg={hoveredWalmart ? 'vBlue.500' : 'white'}
						color={hoveredWalmart ? 'white' : 'gray.800'}
						_hover={{ bg: 'vBlue.500', color: 'white', borderColor: 'vBlue.500' }}
						mt={-12}
						w='95%'
						onClick={handleWalmartSellerConnect}
					>
						<VStack spacing={2}>
							<Text
								fontSize={{ base: 'lg', md: '2xl' }}
								fontWeight='bold'
								color={hoveredWalmart ? 'white' : 'gray.800'}
							>
								Connect to Walmart
							</Text>
							<Box w='100%' padding='0 10px' alignSelf='flex-start'>
								<Text
									fontSize={{ base: 'xs', md: 'sm' }}
									color={hoveredWalmart ? 'white' : 'gray.500'}
									whiteSpace='normal'
								>
									Connect your Walmart Seller Central account to Valence Intelligence
								</Text>
							</Box>
						</VStack>
					</Button>
				</VStack>
			</HStack>

			<Box w='100%' bg='white' p={{ base: 3, md: 5 }} textAlign='center' position='relative' bottom='0'>
				<HStack spacing={{ base: 2, md: 4 }} justify='center' wrap='wrap'>
					<Button onClick={handlePolicy} bg='white' variant='link'>
						<Text fontSize={{ base: 'xs', md: 'sm' }} color='gray.600'>
							Privacy Policy
						</Text>
					</Button>
					<Button onClick={handleTerms} bg='white' variant='link'>
						<Text fontSize={{ base: 'xs', md: 'sm' }} color='gray.600'>
							Terms of Use
						</Text>
					</Button>
					<Text fontSize={{ base: 'xs', md: 'sm' }} color='gray.600'>
						© 2024, Valence Intelligence
					</Text>
				</HStack>
			</Box>
		</Card>
	)
}

export default MarketplaceSelection
